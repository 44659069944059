import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Paper,
  createTheme,
  ThemeProvider,
  Alert,
} from '@mui/material';
import { submitAlphaTestSignup } from '../services/api';
import { AlphaTestSignup } from '../types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#250e38',
    },
    secondary: {
      main: '#250e38',
    },
  },
});

const SignupForm: React.FC = () => {
  const [formData, setFormData] = useState<AlphaTestSignup>({
    fullName: '',
    email: '',
    alphaTesting: false,
    newsletter: false,
  });
  const [errors, setErrors] = useState<Partial<AlphaTestSignup>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const validateForm = (): boolean => {
    const newErrors: Partial<AlphaTestSignup> = {};
    
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    
    if (!formData.alphaTesting) {
      newErrors.alphaTesting = true;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitStatus(null);
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await submitAlphaTestSignup(formData);
      if (response.success) {
        setSubmitStatus({
          type: 'success',
          message: response.message || 'Thank you for signing up! We will contact you soon.'
        });
        setFormData({
          fullName: '',
          email: '',
          alphaTesting: false,
          newsletter: false,
        });
      } else {
        setSubmitStatus({
          type: 'error',
          message: response.message || 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'An error occurred. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Alpha Testing Signup
        </Typography>
        <Typography variant="body1" paragraph align="center" color="text.secondary">
          Join our alpha testing program and help shape the future of Universale
        </Typography>

        {submitStatus && (
          <Alert 
            severity={submitStatus.type} 
            sx={{ mb: 2 }}
            onClose={() => setSubmitStatus(null)}
          >
            {submitStatus.message}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="newsletter"
                    checked={formData.newsletter}
                    onChange={handleChange}
                  />
                }
                label="Subscribe to our newsletter for updates"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="alphaTesting"
                    checked={formData.alphaTesting}
                    onChange={handleChange}
                    required
                  />
                }
                label="I agree to participate in alpha testing"
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !formData.alphaTesting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </ThemeProvider>
  );
};

export default SignupForm; 