import axios from 'axios';
import { AlphaTestSignup, ApiResponse } from '../types';

// Create API instance with relative paths
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export const submitAlphaTestSignup = async (data: AlphaTestSignup): Promise<ApiResponse> => {
  try {
    console.log('Submitting signup data:', data);
    const response = await api.post('/signup', {
      email: data.email,
      name: data.fullName,
      alphaTesting: data.alphaTesting,
      newsletter: data.newsletter
    });
    console.log('Signup response:', response.data);
    return {
      success: true,
      message: response.data.message,
      data: response.data.data
    };
  } catch (error: any) {
    console.error('Error submitting signup:', error);
    console.error('Error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while submitting the form'
    };
  }
};

export default api; 